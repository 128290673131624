<template>
  <div class="home-page">
    <div class="app-container">
      <!-- Layout -->
      <el-row :gutter="20" class="row-bg" justify="center">
        <!-- Content -->
        <el-col :sm="24" :md="20">
          <el-form ref="videoForm" :model="videoForm" :rules="videoRules">
            <!-- Row 1 -->
            <el-row :gutter="20">
              <el-col :md="16">
                <div class="brand-color mb-5">タイトル</div>
                <el-form-item prop="video_url">
                  <el-input
                    v-model="videoForm.video_url"
                    size="small"
                    tabindex="1"
                    disabled
                  />
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-row :gutter="10">
                  <el-col :md="8">
                    <div class="brand-color mb-5">&nbsp;</div>
                    <el-button size="small" type="success" :disabled="isCreateForm" @click="previewVideo">プレビュー</el-button>
                  </el-col>
                  <el-col :md="12" class="upload">
                    <div class="brand-color mb-5">動画</div>
                    <input ref="file" type="file" style="display: none" @change="handleFileUpload">
                    <el-button size="small" type="primary" @click="$refs.file.click()">動画を変更</el-button>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- Row 2 -->

            <el-row :gutter="10" class="mt-50 mb-50 text-center">
              <el-col>
                <el-button
                  v-if="isCreateForm"
                  type="primary"
                  size="small"
                  @click="handleCreate"
                >作成</el-button>
                <el-button
                  v-else
                  type="primary"
                  size="small"
                  @click="handleUpdate"
                >保存</el-button>
                <el-button
                  type="info"
                  size="small"
                  @click="handleCancel"
                >キャンセル</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import { fetchVideo, uploadVideo, updateVideo } from '@/api/training.js'
import { uploadImage } from '@/api/upload.js'

export default {
  name: 'VideoDetail',
  components: {},
  data() {
    return {
      isCreateForm: false,
      video_id: null,
      videoForm: {
        training_id: null,
        video_url: null,
        video_type: 1
      },
      videoRules: {
        video_url: [
          { required: true, trigger: 'blur', message: ' ' }
        ]
      },
      previewVideoUrl: process.env.VUE_APP_BASE_STORAGE,
      old_file_url: ''
    }
  },
  created() {
    this.getVideoUrl()
  },
  methods: {
    getVideoUrl() {
      this.videoForm.training_id = this.$route.params.id
      fetchVideo(this.videoForm.training_id).then((response) => {
        if (response.data === null) {
          this.isCreateForm = true
        } else {
          this.videoForm.video_url = response.data.video_url
          this.isCreateForm = false
          this.previewVideoUrl = this.previewVideoUrl + '/' + this.videoForm.video_url
          this.video_id = response.data.id
          this.old_file_url = response.data.video_url
        }
      })
    },

    upload(video) {
      const formData = new FormData()
      formData.append('file', video)
      formData.append('folder_name', 'trainings')
      formData.append('type', 'video')
      uploadImage(formData).then((response) => {
        this.videoForm.video_url = response.data.url
        uploadVideo(this.videoForm).then((response) => {
          this.$notify({
            title: 'Success',
            message: 'データは正常に更新されました',
            type: 'success',
            duration: 2000
          })

          // Just to simulate the time of the request
          setTimeout(() => {
            this.dataLoading = false
            this.handleCancel()
          }, 0.1 * 1000)
        })
      })
        .catch(() => {
          this.$notify({
            title: 'Error',
            message: 'エラーが発生しました',
            type: 'error',
            duration: 2000
          })
          this.loading = false
        })
    },

    handleFileUpload(e) {
      const file = e.target.files[0]
      this.video = file
      this.videoForm.video_url = file.name
    },

    handleCreate() {
      this.$refs.videoForm.validate((valid) => {
        if (valid) {
          if (this.video) {
            this.upload(this.video)
          }
        } else {
          this.$notify({
            title: 'Error',
            message: '「動画」を入力してください',
            type: 'error',
            duration: 2000
          })
        }
      })
    },

    handleUpdate() {
      this.$refs.videoForm.validate((valid) => {
        if (valid) {
          if (this.video && this.videoForm.video_url != null) {
            const uploadFormData = new FormData()
            uploadFormData.append('file', this.video)
            uploadFormData.append('folder_name', 'trainings')
            uploadFormData.append('type', 'video')
            if (!this.isCreateForm) {
              uploadFormData.append('old_file', this.old_file_url)
            }
            uploadImage(uploadFormData).then((response) => {
              this.videoForm.video_url = response.data.url
              updateVideo(this.video_id, this.videoForm).then((response) => {
                this.$notify({
                  title: 'Success',
                  message: 'データは正常に更新されました',
                  type: 'success',
                  duration: 2000
                })

                // Just to simulate the time of the request
                setTimeout(() => {
                  this.dataLoading = false
                  this.handleCancel()
                }, 0.1 * 1000)
              })
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    handleCancel() {
      const matched = this.$route.matched.filter((item) => item.name)
      this.$router.push(matched[0].redirect)
    },

    previewVideo() {
      window.open(this.previewVideoUrl)
    }
  }
}
</script>

<style scoped>
  .upload {
    margin-left: 10px;
  }

  @media only screen and (max-width: 1200px) {
    .upload {
      margin-left: 25px;
  }
  }

  @media only screen and (max-width: 990px) {
    .upload {
      margin: 10px 0 0 0;
  }
  }
</style>

