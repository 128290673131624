<template>
  <video-detail />
</template>

<script>
import VideoDetail from './components/VideoDetail.vue'

export default {
  name: 'Video',
  components: {
    VideoDetail
  }
}
</script>

